import React, { useEffect, useContext } from 'react'
import querystring from 'querystring'
import { useLazyQuery } from '@apollo/react-hooks'
import { UserContext } from '../contexts/user'
import getMe from '../api/me.graphql'
import { setToken } from '../api/token'

import Layout from '../components/layout'
import Logo from '../components/logo'
import SEO from '../components/seo'

import './index.css'

const isValidUrl = (string?: string) => {
  if (!string) {
    return false
  }
  try {
    new URL(decodeURIComponent(string))
    return true
  } catch (_) {
    return false
  }
}

const OauthRedirectPage = ({ location }: { location: Location }) => {
  const { setUser } = useContext(UserContext)
  const [fetchUser] = useLazyQuery(getMe, {
    onCompleted: (data) => {
      setUser(data.getMe)
    },
  })

  const { token: _token, redirect: _redirect } = querystring.parse(
    location.search.replace(/^\?/, '')
  )

  const redirect = Array.isArray(_redirect) ? _redirect[0] : _redirect
  const token = Array.isArray(_token) ? _token[0] : _token

  const isValidRedirectURL = isValidUrl(redirect)

  useEffect(() => {
    if (!redirect && window.opener) {
      ;(window.opener as Window).postMessage(
        { type: 'signin-complete', token },
        '*'
      )
      window.close()
      return
    }

    // special case for the native app
    if (isValidRedirectURL) {
      window.location.href = `${decodeURIComponent(redirect)}?token=${token}`
    }
    setToken(token)
    fetchUser()
  }, [token, redirect, isValidRedirectURL])

  return (
    <Layout>
      <SEO title="Lona" />
      <Logo />
      {isValidRedirectURL || !token ? null : (
        <div>
          Copy paste the following token in {redirect}
          <p>{token}</p>
        </div>
      )}
    </Layout>
  )
}

export default OauthRedirectPage
